@import "./menu.scss";
@import "./splide-sea-green.min.css";
@import "./splide.scss";

html {
  scroll-behavior: smooth; /* Add smooth scrolling behavior */
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  font-family: "Inter", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.menu-header {
  overflow-x: scroll;
  overflow-y: hidden;
}

div.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35vh;

  .splash {
    width: 50vw;
  }

  .sub-splash {
    position: absolute;
    left: 3vw;
    bottom: 5vh;
    margin-top: 5vh;
    display: flex;

    img {
      height: 5rem;
      width: auto;
    }
  }

  div.main {
    padding: 0px 20px;
    color: $blue-logo;
    margin-top: 1.5vh;
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      align-content: center;
      text-align: center;
    }

    i {
      margin-right: 10px;
    }

    a {
      text-decoration: none;
      color: $blue-logo;
    }

    a:hover {
      color: $purple-highlight;
      transition: color 0.5s ease;
    }
  }
}

@media (max-width: 1000px) {
  div.container {
    div.header {
      img {
        width: 70vw;
        height: auto;
      }
    }

    div.main {
      width: 85vw;
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  div.container {
    div.header {
      img {
        width: 70vw;
        height: auto;
      }
    }

    div.main {
      width: 75vw;
      flex-direction: column;
    }
  }

  .sub-splash {
    img {
      height: 3rem;
      width: auto;
    }
  }
}

@media (max-width: 425px) {
  div.container {
    margin-top: 30vh;

    div.header {
      img {
        width: 90vw;
        height: auto;
      }
    }

    div.main {
      .header {
        height: 20vh;
      }
    }

    div.main {
      width: 60vw;
      flex-direction: column;
    }
  }

  .sub-splash {
    img {
      height: 3rem;
      width: auto;
    }
  }
}

header {
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  left: 0;

  background-color: white;
  color: $blue-logo;
  padding: 1rem 2rem;
  max-height: 3vh;
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: "Inter", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
nav ul li {
  margin-right: 20px;
}
nav ul li:last-child {
  margin-right: 0;
}
nav ul li a {
  color: $blue-logo;
  text-decoration: none;
  transition: color 0.3s ease;
}
nav ul li a:hover {
  color: $purple-highlight;
}

.active {
  transition: color 0.5s ease-out;
  color: $purple-highlight;
}

/* Stili per il menu a tendina */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}
.dropdown-content a {
  color: $blue-logo;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: 10em;
  border-left: 10px;
  border-color: $blue-logo;
  border-width: 10px;
}

/* Aggiungi questa regola per creare la freccia sul link del padre nel menu a tendina */
.dropdown-content a::before {
  content: "";
  display: inline-block;
  border: solid $blue-logo;
  border-width: 0 1px 1px 0;
  padding: 3px;
  margin-right: 8px;
  transform: rotate(0deg);
}

/* Aggiungi questa regola per nascondere la freccia sulla voce del menu padre senza figli */
.dropdown > a::before {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.breaker {
  margin-top: 3vh;
}

@media (max-width: 1000px) {
  .breaker {
    margin-top: 6vh;
    height: 10px;
  }
}

.h1-header {
  color: $yellow-highlight;
  font-size: xx-large;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0.5rem;
  text-decoration-color: $yellow-highlight;
  text-underline-offset: 0.5rem;
  margin-bottom: 2rem;
}

.h1-header-blue {
  @extend .h1-header;
  color: $purple-highlight;
  text-decoration-color: $purple-highlight;
}

@media (max-width: 1000px) {
  div.container {
    .splash {
      width: 65vw;
    }
  }
}

@media (max-width: 375px) {
  div.container {
    .splash {
      width: 90vw;
    }
  }
}
