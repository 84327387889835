@import "colors.scss";

input {
    position: fixed;
    visibility: hidden;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 15px;
}

input+label {
    position: fixed;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 15px;
    z-index: 19;

    span {
        position: absolute;
        width: 100%;
        height: 3px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        display: block;
        background-color: $blue-logo;
        transition: 1s;
    }

    span:first-child {
        top: 3px;
    }

    span:nth-child(2) {
        top: 9px;
    }

    span:last-child {
        top: 15px;
    }
}

label:hover {
    cursor: pointer;
}

input:checked+label {
    span {
        opacity: 0;
        top: 50%;
    }

    span:first-child {
        opacity: 1;
        transform: rotate(405deg);
    }

    span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
    }
}

input~nav {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    z-index: 17;
    transition: .5s;
    transition-delay: .5s;
    overflow: hidden;
}

input:checked~nav {
    height: 100%;
    transition-delay: 0s;
    background-color: $blue-logo;

    .Menu-list-item {
        opacity: 1;
        transition-delay: .5s;
    }
}