.splide__track {
  height: 75vh;
}

.splide__slide img {
  width: auto;
  height: 100%;
  object-fit: contain;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
}

@media (max-width: 750px) {
  .splide__slide img {
    width: auto;
    height: auto;
    max-width: 80vw;
    object-fit: contain;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 10px;
  }
}

.splide__slide div {
  position: absolute;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  top: 0;
  left: 0;
  width: auto;
  background-color: $purple-highlight; /* Semi-transparent background */
  color: white;
  padding: 10px;
  box-sizing: border-box;
}

.splide__pagination__page.is-active {
  background: $blue-logo;
}

.splide__pagination__page:hover {
  background: $purple-highlight;
}

.splide__arrow svg {
  fill: $blue-logo;
}

.splide__arrow:hover:not(:disabled) svg {
  fill: $purple-highlight;
}
